const LegalLoginFooter = ({ visible, hide }) => {
  return (
    <div className="bg-white w-full flex justify-center py-8 px-4 lg:px-12 bg-opacity-80">
      <div className="container flex flex-col text-[9pt] gap-4">
        <p>VIVUS es un producto de Difinance, S.A. de C.V., SOFOM, E.N.R. y es una marca protegida y registrada bajo las leyes mexicanas. Todos los derechos reservados.</p>
        <p>
          Recuerda que VIVUS no opera con intermediarios y que la solicitud de tu crédito debes llevarla a cabo de manera personal de conformidad con nuestros Términos y Condiciones, así como con las leyes y reglamentos aplicables, por lo que nos deslindamos expresamente de cualquier responsabilidad
          derivada de una solicitud realizada en incumplimiento a lo anterior incluyendo, sin limitar, que un tercero solicite un crédito a tu nombre.
        </p>
        <p>
          Difinance, S.A. de C.V., SOFOM, E.N.R., se encuentra en Ceres 23, Crédito Constructor, 03940, Benito Juárez, Ciudad de México. Para mayor información, contactar a info@vivusmx.com. Según las normas aplicables a Difinance, S.A. de C.V., SOFOM, E.N.R., No requiere de autorización de la
          Secretaria de Hacienda y Crédito Público para organizarse y operar como una Sociedad Financiera de Objeto Múltiple, Entidad No Regulada, y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores únicamente para efectos del artículo 56 de la Ley General de Organizaciones
          y Actividades Auxiliares del Crédito.
        </p>
        <p>
          El plazo del crédito quedará establecido conforme a lo pactado entre el cliente y DIFINANCE, el cual puede variar entre 62 a 90 días. Los créditos personales podrán ser adquiridos en toda la República Mexicana. No existen renovaciones automáticas de créditos. Cualquier préstamo o monto
          adicional otorgado por DIFINANCE deberá ser solicitado por el cliente, quien deberá aceptar explícitamente las condiciones de montos y plazos previamente. El término Comisiones se refiere a Comisión por apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de
          Investigación 2.5%, más IVA cada una. Todas las comisiones son calculadas sobre el monto del crédito.{" "}
          <a href="/contenido/terminos-y-condiciones" className="text-green-dark font-bold underline">
            Aplican restricciones
          </a>
        </p>
        <p>
          <span className="bold-type type-up"> *Sujeto a aprobación de:</span> crédito, que la información y fotografías solicitadas cumplan con los requisitos y de acuerdo a las políticas internas de aprobación.
        </p>

        <p>
          <span className="bold-type type-up">CAT:</span> la tasa de interés mensual ordinaria aplicable es de <span className="bold-type type-up">42.58%</span> más el IVA. Por defecto, la tasa de interés anual fija es de <span className="bold-type type-up">511.0%</span> más el IVA más Comisión por
          apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de Investigación 2.5%, más IVA cada una. O un <span className="bold-type type-up">Costo Anual Total (CAT)</span> PROMEDIO: <span className="bold-type type-up">8969.90%</span> sin IVA por $12,000 M.N. calculado sobre
          un préstamo de 90 días. Solamente para información y fines comparativos. Calculado a septiembre de 2024.
        </p>
        <p>
          Entre las implicaciones que podrían resultar de la falta de pago están: (1) Intereses moratorios a una tasa anual fija de
          <span className="bold-type type-up"> 730%</span> equivalente al <span className="bold-type type-up">2%</span> diario, (2) Gestiones de cobranza al teléfono y domicilio del cliente, (3) Afectación del historial de crédito, (4) Implicaciones legales derivadas del incumplimiento del contrato.
        </p>
        <p>
          El Buró de Entidades Financieras es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen las entidades financieras, sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas en que incurren, las sanciones administrativas que les
          han impuesto, las cláusulas abusivas de sus contratos y otra información que resulte relevante para informarte sobre su desempeño. Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, sociedades financieras de objeto múltiple, cajas de ahorro, afores,
          entre otras entidades. Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y tendrás mayores elementos para elegir lo que más te convenga. Esta información te será útil para elegir un producto financiero y también para conocer y usar mejor los que ya
          tienes. Este Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento económico del país, al promover la competencia entre las instituciones financieras; que impulsará la transparencia al revelar información a los usuarios sobre el desempeño de éstas y los
          productos que ofrecen y que va a facilitar un manejo responsable de los productos y servicios financieros al conocer a detalle sus características.
        </p>
      </div>
    </div>
  );
};

export default LegalLoginFooter;
