import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./popupFooter.css";

export const Footer = ({ location }) => {
  const [popup, setPopup] = useState(false);
  const supportPerson = process.env.REACT_APP_SITE === "mivivus" ? "/img/footer/support-mivivus.png" : "/img/footer/support.webp";
  return (
    <>
      <section className="text-white flex flex-col">
        <div className="bg-green-light w-full flex justify-center">
          <div className="container justify-between flex flex-col-reverse lg:flex-row p-4">
            <div className="flex flex-col-reverse items-center gap-4 lg:flex-row">
              <img src={supportPerson} alt="customer service" className="-mb-4 w-40" />
              <div className="flex flex-col gap-2 lg:text-left items-center text-center lg:items-start justify-center">
                <h3 className="text-2xl">
                  <strong>¿Necesitas ayuda?</strong>
                </h3>
                <p className="text-lg">Contáctanos</p>
                <p className="text-md">Por favor, si tienes alguna duda, ponte en contacto con nosotros.</p>
              </div>
            </div>
            <div className="flex flex-col py-12 lg:py-0 items-center lg:items-end justify-center gap-4">
              <div className="flex gap-4">
                <div className="flex flex-col items-center gap-2">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faPhone} />
                  <a href="tel:5567170750">(55) 9417 6019</a>
                </div>
                <a href="mailto:info@vivusmx.com" className="flex flex-col items-center gap-2">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faEnvelope} />
                  <span>info@vivusmx.com</span>
                </a>
              </div>
              <div className="flex flex-col items-center text-center lg:text-left lg:items-end">
                <p>
                  <strong>Nuestro horario de atención al cliente es:</strong>
                </p>
                <p>Lunes a Viernes de 8:00 a 20:00 / Sábado de 8:00 a 14:00</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="flex justify-center">
          <div className="container px-4 py-12 lg:py-4 gap-4 lg:justify-end justify-center items-center flex flex-col lg:flex-row">
            <Link to={location === "manteinance" ? "#" : "/contenido/aviso-de-privacidad"} className="text-gray-500 underline hover:text-gray-800 transition-all">
              Aviso de privacidad
            </Link>
            <Link to={location === "manteinance" ? "#" : "/contenido/terminos-y-condiciones"} className="text-gray-500 underline hover:text-gray-800 transition-all">
              Términos y Condiciones
            </Link>
            <Link to={location === "manteinance" ? "#" : "/contenido/despachos-de-cobranza"} className="text-gray-500 underline hover:text-gray-800 transition-all">
              Despachos de cobranza
            </Link>

            <a href="https://www.condusef.gob.mx/" target="_blank">
              <img width="90px" src="/img/condusef.jpeg" alt="buro" />
            </a>
            <a href="https://www.buro.gob.mx/" target="_blank">
              <img width="50px" src="/img/logo-buro.png" alt="buro" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
